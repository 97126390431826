/* eslint-disable react/prop-types */
import './CompanyTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faLink,
  faLinkSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

function TableHeaderItem({ label, onClick, orderIcon, columnClass }) {
  return (
    <th className={columnClass ?? ''}>
      <button type="button" className="sortable" onClick={onClick}>
        {label}
      </button>
      <FontAwesomeIcon icon={orderIcon ?? faSort} />
    </th>
  );
}

export default function CompanyTable({
  children,
  order,
  sortByName,
  sortByScore,
}) {
  const [arrowIcon, setArrowIcon] = useState();
  const [currentOrdered, setCurrentOrdered] = useState('');

  function OnSort() {
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  return (
    <table className="company-table">
      <thead>
        <tr>
          <TableHeaderItem
            label="Klientnamn"
            columnClass="name-column"
            onClick={() => {
              sortByName();
              OnSort();
              setCurrentOrdered('name');
            }}
            orderIcon={currentOrdered === 'name' ? arrowIcon : null}
          />
          <TableHeaderItem
            label="Score (BETA)"
            columnClass="grade-column"
            onClick={() => {
              sortByScore();
              OnSort();
              setCurrentOrdered('score');
            }}
            orderIcon={currentOrdered === 'score' ? arrowIcon : null}
          />
          <th className="connection-column">Bokföring</th>
          <th className="connection-column">Bank</th>
          <th className="connection-column">Skatteverket</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

CompanyTable.Item = function Item({ client, score, onClick }) {
  return (
    <tr className="company-item-wrapper" onClick={onClick}>
      <td className="name-column">{client.name}</td>
      <td className="grade-column">
        <p
          className={`grade-icon ${
            !client.externalConnectionActive && !client.bankConnectionActive
              ? 'grade-icon-disconnected grade--'
              : `grade-${score?.overallScore ?? '-'}`
          } `}
        >
          {score?.overallScore ?? '-'}
        </p>
      </td>
      <td className="connection-column">
        <FontAwesomeIcon
          className={`connection-icon
            ${client.externalConnectionActive ? 'connected' : 'not-connected'}
          `}
          icon={client.externalConnectionActive ? faLink : faLinkSlash}
        />
      </td>
      <td className="connection-column">
        <FontAwesomeIcon
          className={`connection-icon
            ${client.bankConnectionActive ? 'connected' : 'not-connected'}
          `}
          icon={client.bankConnectionActive ? faLink : faLinkSlash}
        />
      </td>
      <td className="connection-column">
        <FontAwesomeIcon
          className={`connection-icon
            ${client.skvConnected ? 'connected' : 'not-connected'}
          `}
          icon={client.skvConnected ? faLink : faLinkSlash}
        />
      </td>
    </tr>
  );
};
