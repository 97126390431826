const triggerTexts = {};

triggerTexts[0] = {
  SE: {
    name: 'Resultat',
  },
};

triggerTexts[1] = {
  SE: {
    name: 'Kundfakturor',
  },
};

triggerTexts[2] = {
  SE: {
    name: 'Periodiseringar ej utförda',
  },
};

triggerTexts[3] = {
  SE: {
    name: 'Observationskonto grupp 1',
  },
};

triggerTexts[4] = {
  SE: {
    name: 'Observationskonto grupp 2',
  },
};

triggerTexts[5] = {
  SE: {
    name: 'Ej behandlade fakturor',
  },
};

triggerTexts[6] = {
  SE: {
    name: 'Ej avdragsgilla kostnader',
  },
};

triggerTexts[7] = {
  SE: {
    name: 'Avskrivningar',
  },
};

triggerTexts[8] = {
  SE: {
    name: 'Större summor på observationskonto',
    info: (
      <p>
        Observationskonto är ett konto som används för att spåra och övervaka
        transaktioner som behöver ytterligare granskning eller analys innan de
        bokförs korrekt. Det är vanligtvis ett tillfälligt konto där
        transaktioner tillfälligt placeras tills de kan hanteras och bokföras på
        rätt sätt.
        <br />
        <br />
        När en transaktion är osäker eller kräver ytterligare verifiering kan
        den placeras på ett observationskonto istället för att omedelbart
        bokföras på ett permanent konto i huvudboken. Detta ger bokförare och
        revisorer tid att undersöka och förstå transaktionen mer noggrant innan
        den slutligen bokförs korrekt.
        <br />
        <br />
        Definitionen kan innebära dålig internkontroll då det saknas material
        att bokföra, i värsta fall kan det innebära att en summa på
        observationskontot måste ses som ett lån från bolaget till ägare.
      </p>
    ),
    requirement: (
      <p>Skall utredas och bokföras inom ramen för svensk bokföringslag</p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          För att hantera situationen med observationskonto kan du ställa frågor
          om:
        </p>
        <ol>
          <li>Transaktioner med oklara affärsändamål eller avsändare.</li>
          <li>Transaktioner med felaktiga eller ofullständiga dokument.</li>
          <li>
            Transaktioner som behöver godkännande från högre befattningar innan
            de bokförs.
          </li>
          <li>
            Transaktioner som behöver ytterligare kontroll för att säkerställa
            att de följer företagets policyer och förfaranden.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[9] = {
  SE: {
    name: 'Hög skuldsättningsgrad',
  },
};

triggerTexts[10] = {
  SE: {
    name: 'Låg soliditet',
  },
};

triggerTexts[11] = {
  SE: {
    name: '45+ dagar sedan bokföring',
    info: (
      <p>
        Att ha material som är försenat i bokföringen kan påverka företagets
        redovisning och finansiell rapportering.
        <br />
        <br />
        Det kan leda till oegentligheter eller brister i redovisningen och kan
        påverka företagets förmåga att fatta välgrundade beslut baserade på dess
        ekonomiska ställning.
        <br />
        <br />
        Därför är det viktigt för företag att ha effektiva processer och
        kontroller på plats för att minimera risken för försenat bokfört
        material.
      </p>
    ),
    requirement: (
      <p>
        Krav på bokföring: Lagar om bokföring fastställer vanligtvis att företag
        måste föra en korrekt och fullständig bokföring av sina transaktioner
        och händelser.
        <br />
        <br />
        En transaktion skall senast bokföras månaden efter transaktion. Sen
        eller eftersatt bokföring betraktas i vissa fall som bokföringsbrott.
        <br />
        <br />
        Bokföringsbrott är allvarliga överträdelser och kan leda till rättsliga
        påföljder, såsom böter och fängelsestraff, samt till ekonomiska
        förluster för företaget och dess intressenter. Dessa brott underminerar
        förtroendet för företagets finansiella rapporter och kan skada dess
        rykte och långsiktiga överlevnad. Därför är det avgörande för företag
        och deras anställda att följa lagar och regler som styr bokförings- och
        redovisningspraxis noggrant
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          För att hantera situationen med sen bokföring kan du ställa följande
          frågor:
        </p>
        <ol>
          <li>Fråga vem det är som sköter bokföringen.</li>
          <li>
            Be ansvarig kontakta sin redovisningsekonom för att säkerställa att
            allt är bokfört.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[12] = {
  SE: {
    name: 'Likviditet',
  },
};

triggerTexts[13] = {
  SE: {
    name: 'Förfallna kundfakturor',
  },
};

triggerTexts[14] = {
  SE: {
    name: 'Förfallna leverantörsfakturor',
  },
};

triggerTexts[15] = {
  SE: {
    name: 'Kortfristig likviditet',
  },
};

triggerTexts[16] = {
  SE: {
    name: 'Skatt',
  },
};

triggerTexts[17] = {
  SE: {
    name: 'Uppvisar stora räntekostnader',
    info: (
      <p>
        Kostnadsräntor är ett är ett resultat av bolagets skulder. Skulderna
        genererar räntor. Kostnadsräntor kan också vara ett resultat av bolagets
        sena betalningar till sina leverantör vilket kan indikera risk att den
        finansiella hälsan inte är optimal.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag redovisar kostnadsräntor finns det flera viktiga
          aspekter att ta hänsyn till för att säkerställa en korrekt och säker
          hantering av denna information. Här är några centrala punkter att
          överväga:
        </p>
        <ol>
          <li>
            Finansiell hälsa: Fråga om betalningssvårigheter, detta då
            kostnadsräntor är ett tecken på detta!
          </li>
          <li>
            Överväg noggrant er borgenärsroll till bolaget. Kommer vi att bli
            utsatta för sena betalningar?
          </li>
          <li>
            Kan vi hjälpa aktören med bättre lån och finansiering så att räntan
            kan gå ner?
          </li>
          <li>
            Kan bolagets skulder re-finansieras och genom det sänka
            räntekostnaderna?
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[18] = {
  SE: {
    name: 'Uppvisar stora ränteintäkter',
    info: (
      <p>
        Intäktsräntor är en intäkt som aktören får genom utlånat kapital och
        material. Intäktsräntor är också ett resultat av att bolagets kunder
        betalar sent och därför ackumuleras en ränta.
        <br />
        <br />
        Intäktsräntor kan vara ett tecken på dåliga betalare som i längden
        påverkar bolagets verksamhet i stort.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag redovisar intäktsräntor finns det flera viktiga aspekter
          att ta hänsyn till för att säkerställa en korrekt och säker hantering
          av denna information. Här är några centrala punkter att överväga:
        </p>
        <ol>
          <li>Vad beror sena betalningar på?</li>
          <li>Hur lång kredittid har kunden?</li>
          <li>Hur ofta följer man upp inbetalning?</li>
          <li>
            Kan kundens fakturor komma att innebära kundförluster på längre
            sikt?
          </li>
          <li>
            Skulle bolaget kunna använda sig av factoring lösning för kortare
            kredittider?
          </li>
          <li>Skulle kundens fakturor komma att innebära regresser?</li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[19] = {
  SE: {
    name: 'Moms',
  },
};

triggerTexts[20] = {
  SE: {
    name: 'Negativt saldo på skattekonto',
    info: (
      <p>
        Ett underskott på ditt skattekonto innebär att du har mer skatteskuld än
        det belopp som finns på ditt konto. Detta kan inträffa av olika skäl:
        <br />
        <br />
        1. <b>Ofullständig betalning av skatt:</b> Du har inte betalat
        tillräckligt med skatt för att täcka din skatteskuld.
        <br />
        <br />
        2. <b>Skatteskuld från tidigare år:</b> Du kan ha en oupptäckt
        skatteskuld från tidigare år som nu har lagts till ditt skattekonto.
        <br />
        <br />
        3. <b>Betalningsproblem:</b> Det kan finnas problem med betalningar
        eller avdrag som resulterar i ett underskott på ditt skattekonto.
        <br />
        <br />
        Ett underskott på ditt skattekonto är viktigt att hantera eftersom det
        kan leda till påföljder och räntor från skattemyndigheten om det inte
        åtgärdas i tid
      </p>
    ),
    requirement: (
      <p>
        Bolaget skall betala sina skatter och avgifter i tid. Att inte betala
        skatteverket i tid leder till en oren revision i årsredovisningen som
        kan belasta bolagets kreditvärdighet.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <ol>
          <li>
            <b>Identifiera orsaken till förseningen:</b> För att kunna hantera
            problemet är det viktigt att förstå varför betalningen blev
            försenad. Det kan vara på grund av ekonomiska svårigheter,
            missförstånd av skattekraven eller andra faktorer.
          </li>
          <li>
            <b>Kontakta skattemyndigheten:</b> Det bästa steget är att
            omedelbart kontakta skattemyndighet och få förklarat situationen.
          </li>
          <li>
            <b>Be om historiska transaktioner:</b> Skatteverket är offentligt
            vilket gör att du kan begära skatteinformation. Här ser du också
            ifall bolaget är sen flertalet gånger.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[21] = {
  SE: {
    name: 'Positiv försäljningstrend',
  },
};

triggerTexts[22] = {
  SE: {
    name: 'Negativ försäljningstrend',
    info: (
      <p>
        Xpektors algoritmer ger dig en varning när företagets omsättning sjunker
        tre månader i rad. Detta är ett tecken på att bolagets finansiella hälsa
        måste bli bättre.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag redovisar en sjunkande försäljning eller omsättning
          finns det flera viktiga aspekter att ta hänsyn till för att
          säkerställa en korrekt och säker hantering av denna information. Här
          är några centrala punkter att överväga:
        </p>
        <ol>
          <li>Vad beror den sjunkande försäljningen på?</li>
          <li>Är det förväntat på grund av säsong?</li>
          <li>Kommer företaget att bryta trenden?</li>
          <li>Är det att förvänta sig att månader kommer att sjunka?</li>
          <li>Kan bolaget uppvisa avtal eller offerter?</li>
          <li>Skulle bolaget må bättre av ett bryggkapital?</li>
          <li>
            Är finansiering av tillgångar eller re-finansiering ett alternativ?
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[23] = {
  SE: {
    name: 'Väsentlig omsättningsvariation',
  },
};

triggerTexts[24] = {
  SE: {
    name: 'Övriga externa kostnader ökar',
  },
};

triggerTexts[25] = {
  SE: {
    name: 'Övriga externa kostnader minskar',
  },
};

triggerTexts[26] = {
  SE: {
    name: 'Har ingen försäkring',
  },
};

triggerTexts[27] = {
  SE: {
    name: 'Redovisar advokatkostnader',
  },
};

triggerTexts[28] = {
  SE: {
    name: 'Lager ökar',
  },
};

triggerTexts[29] = {
  SE: {
    name: 'Lager oförändrat',
  },
};

triggerTexts[30] = {
  SE: {
    name: 'Immateriella tillgångar ökar',
  },
};

triggerTexts[31] = {
  SE: {
    name: 'Eget kapital har förändrats under månaden',
  },
};

triggerTexts[32] = {
  SE: {
    name: 'Långfristiga skulder har ökat under månaden',
  },
};

triggerTexts[33] = {
  SE: {
    name: 'Prediktivt kassaflöde',
  },
};

triggerTexts[34] = {
  SE: {
    name: 'Inköp av varor ökar',
  },
};

triggerTexts[35] = {
  SE: {
    name: 'Inköp av varor minskar',
  },
};

triggerTexts[36] = {
  SE: {
    name: 'Lön och personalkostnader ökar',
  },
};

triggerTexts[37] = {
  SE: {
    name: 'Lön och personalkostnader minskar',
  },
};

triggerTexts[38] = {
  SE: {
    name: 'Nedskrivningar/Avskrivningar ökar',
  },
};

triggerTexts[39] = {
  SE: {
    name: 'Nedskrivningar/Avskrivningar minskar',
  },
};

triggerTexts[40] = {
  SE: {
    name: 'Övriga finansiella kostnader ökar',
  },
};

triggerTexts[41] = {
  SE: {
    name: 'Övriga finansiella kostnader minskar',
  },
};

triggerTexts[42] = {
  SE: {
    name: 'Negativt banksaldo',
  },
};

triggerTexts[43] = {
  SE: {
    name: 'Betalar inte skatter och avgifter i tid',
  },
};

triggerTexts[44] = {
  SE: {
    name: 'Kunder betalar inte i tid',
    info: (
      <p>
        Betaltid är den tid då kunden har på sig att betala in en ställd
        faktura, en normal betaltid ligger mellan 10-30 dagar efter att fakturan
        har ställts.
        <br />
        <br />
        Är genomsnittet på inbetalningar högre än utsatt betaltid bör man ställa
        frågor.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag redovisar en högre genomsnittlig betaltid finns det
          flera viktiga aspekter att ta hänsyn till för att säkerställa en
          korrekt och säker hantering av denna information. Här är några
          centrala punkter att överväga:
        </p>
        <ol>
          <li>Vad beror sena betalningar på?</li>
          <li>Hur lång kredittid har kunden?</li>
          <li>
            Kan kundens fakturor komma att innebära kundförluster på längre
            sikt?
          </li>
          <li>
            Skulle bolaget kunna använda sig av factoring lösning för kortare
            kredittider?
          </li>
          <li>Skulle kundens fakturor komma att innebära regresser?</li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[45] = {
  SE: {
    name: 'Redovisar förbjudna lån',
  },
};

triggerTexts[46] = {
  SE: {
    name: 'Hanterar kontanter',
    info: (
      <p>
        Ett bolag som handlar med eller använder kontanter innebär risk.
        Anledningar till kontanthantering kan vara många men i dagens handel
        mellan bolag eller individer bör inte stora mängder kontanter hanteras.
        <br />
        <br />
        Kontanter är en viktig faktor för kriminella nätverk varför ursprunget
        till kontanterna noga bör följas upp.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag hanterar kontanter finns det flera viktiga aspekter att
          ta hänsyn till för att säkerställa en korrekt och säker hantering av
          dessa medel. Här är några centrala punkter att överväga:
        </p>
        <ol>
          <li>
            <b>Intern kontroll:</b> Säkerställ att bolaget har etablerat strikta
            interna kontrollrutiner för att säkerställa att kontanttransaktioner
            övervakas och dokumenteras noggrant. Det kan innefatta att ha
            separata personer ansvariga för olika delar av kontanthanteringen,
            regelbunden avstämning av kassaböcker och kontroller för att
            förebygga och upptäcka bedrägeri eller stöld.
          </li>
          <li>
            <b>Redovisning och rapportering:</b> Se till att bolagets alla
            kontanttransaktioner bokförs korrekt och registreras i företagets
            bokföringssystem Säkerställ spårbarhet.
          </li>
          <li>
            <b>Förebyggande åtgärder mot bedrägeri:</b> Se till att bolaget har
            Implementerat kontroller för att förhindra och upptäcka bedrägeri
            och oegentligheter i kontanthanteringen.{' '}
          </li>
          <li>
            <b>Begär kontantintyg underskrivet av revisor:</b> genom ett
            underskrivet kontantintyg säkerställer du bolagets hantering.
          </li>
          <li>
            <b>Fråga om bankningsrutiner och senaste insättningskvittot:</b>{' '}
            Genom att ställa dessa kontrollfrågor får du möjlighet att förstå om
            kontanterna behandlas rutinmässigt, eller om kontanterna existerar.
          </li>
          <li>
            <b>Ställ frågor:</b> Saknas bankningskvitton? Ställ frågan om
            varför?
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[47] = {
  SE: {
    name: 'Befarar kundförluster',
    info: (
      <p>
        Konstaterad kundförlust kan avse den faktiska förlusten av en kund eller
        intäkter från en kund som redan har inträffat och har dokumenterats
        eller identifierats av företaget.
        <br />
        <br />
        Det kan handla om förlust av en specifik kund, vanligtvis genom avslut
        av affärsrelationen eller uteblivna betalningar, och denna förlust har
        bekräftats eller dokumenterats av företaget.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag redovisar kundförluster finns det flera viktiga aspekter
          att ta hänsyn till för att säkerställa en korrekt och säker hantering
          av denna information. Här är några centrala punkter att överväga:
        </p>
        <ol>
          <li>
            Kundförluster innebär risk för borgenärer eller dennes leverantörer.
          </li>
          <li>
            Viktigt att ställa frågan om det finns andra fakturor som kan tänkas
            vara kundförluster?
          </li>
          <li>
            Finns det anledning att tro att fler kundförluster kommer att
            påverka bolagets ställning?
          </li>
          <li>Beror kundförlusterna på en eller flera kunder?</li>
          <li>Jämför omsättningen med kundförlusten.</li>
          <li>
            Ligger det kundfakturor kvar i företaget som inte är bokförda som
            kundförlust?
          </li>
          <li>
            Är kundförlusterna frekventa varje månad bör man utreda om
            kundförlusterna är ett verktyg.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[48] = {
  SE: {
    name: 'Resultat efter skatt',
  },
};

triggerTexts[49] = {
  SE: {
    name: 'Förbrukat eget kapital',
    info: (
      <p>
        Förbrukat eget kapital inträffar när ett företag förlorar mer pengar än
        det tjänar eller använder upp mer av ägarnas investerade kapital än vad
        som har genererats genom företagsverksamheten.
        <br />
        <br />
        Det är ett tecken på ekonomisk svaghet och kan leda till allvarliga
        konsekvenser, inklusive konkurs.
      </p>
    ),
    requirement: (
      <p>
        Förbrukat eget kapital innebär att befattningshavare måste upprätta en
        så kallad kontrollbalansräkning för att säkerställa att det egna
        kapitalet kan återställas.
        <br />
        <br />
        Kontrollbalansräkning skall upprättas även vid misstanke om att bolaget
        kan komma att ha förbrukat det egna kapitalet.
        <br />
        <br />
        Upprättas ingen kontrollbalansräkning kan ägare och styrelse bli
        skyldiga för bolagets alla skulder.
        <br />
        <br />
        Styrelse, ägare och revisor måste informeras
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          För att hantera situationen med förbrukat eget kapital kan företaget
          vidta följande åtgärder:
        </p>
        <ol>
          <li>
            <b>Öka försäljningen och intäkterna:</b> Företaget kan försöka öka
            sin försäljning genom marknadsföringsinsatser, produktförbättringar
            eller expansion till nya marknader. Genom att öka intäkterna kan
            företaget förbättra sin lönsamhet och återuppbygga sitt eget
            kapital.
          </li>
          <li>
            <b>Kostnadsminskning:</b> Identifiera och minska onödiga kostnader
            och ineffektiviteter inom verksamheten. Det kan innebära att
            rationalisera processer, förhandla om bättre avtal med leverantörer
            eller minska personalstyrkan om det är nödvändigt.
          </li>
          <li>
            <b>Kapitalanskaffning:</b> Företaget kan söka extern finansiering
            genom att låna pengar från banker eller investerare, eller genom att
            utfärda nya aktier för att öka det egna kapitalet.
          </li>
          <li>
            <b>Förbättra lönsamheten:</b> Utvärdera och förbättra företagets
            affärsmodell, prissättningsstrategi och produktmix för att öka
            lönsamheten. Det kan innebära att fokusera på mer lönsamma
            produktsegment eller att diversifiera verksamheten för att minska
            risken.
          </li>
          <li>
            <b>Strategiska samarbeten eller partnerskap:</b> Genom att ingå
            samarbeten eller partnerskap med andra företag kan man dra nytta av
            synergier och öka sin konkurrenskraft på marknaden.
          </li>
        </ol>
        <p>
          Genom att implementera dessa åtgärder kan företaget arbeta mot att
          återställa sitt eget kapital och förbättra sin finansiella hälsa på
          lång sikt.
        </p>
      </div>
    ),
  },
};

triggerTexts[50] = {
  SE: {
    name: 'Försäljning utan F-skatt',
  },
};

triggerTexts[51] = {
  SE: {
    name: 'Försäljning utan momsregistrering',
  },
};

triggerTexts[52] = {
  SE: {
    name: 'Lönekostnad utan arbetsgivarregistrering',
  },
};

triggerTexts[53] = {
  SE: {
    name: 'Innehar ej F-skatt',
  },
};

triggerTexts[54] = {
  SE: {
    name: 'Uppfyller revisorskrav utan registrerad revisor',
  },
};

triggerTexts[55] = {
  SE: {
    name: 'Uppvisar momsskuld men ingen betalning',
  },
};

triggerTexts[56] = {
  SE: {
    name: 'Styrelsen har ändrats',
  },
};

triggerTexts[57] = {
  SE: {
    name: 'Bolaget är i konkurs',
  },
};

triggerTexts[58] = {
  SE: {
    name: 'Sociala kostnader redovisas inte',
  },
};

triggerTexts[59] = {
  SE: {
    name: '30 dagar sedan bokföring',
    info: (
      <p>
        Att ha material som är försenat i bokföringen kan påverka företagets
        redovisning och finansiell rapportering.
        <br />
        <br />
        Det kan leda till oegentligheter eller brister i redovisningen och kan
        påverka företagets förmåga att fatta välgrundade beslut baserade på dess
        ekonomiska ställning.
        <br />
        <br />
        Därför är det viktigt för företag att ha effektiva processer och
        kontroller på plats för att minimera risken för försenat bokfört
        material.
      </p>
    ),
    requirement: (
      <p>
        Krav på bokföring: Lagar om bokföring fastställer vanligtvis att företag
        måste föra en korrekt och fullständig bokföring av sina transaktioner
        och händelser.
        <br />
        <br />
        En transaktion skall senast bokföras månaden efter transaktion. Sen
        eller eftersatt bokföring betraktas i vissa fall som bokföringsbrott.
        <br />
        <br />
        Bokföringsbrott är allvarliga överträdelser och kan leda till rättsliga
        påföljder, såsom böter och fängelsestraff, samt till ekonomiska
        förluster för företaget och dess intressenter. Dessa brott underminerar
        förtroendet för företagets finansiella rapporter och kan skada dess
        rykte och långsiktiga överlevnad. Därför är det avgörande för företag
        och deras anställda att följa lagar och regler som styr bokförings- och
        redovisningspraxis noggrant
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          För att hantera situationen med sen bokföring kan du ställa följande
          frågor:
        </p>
        <ol>
          <li>Fråga vem det är som sköter bokföringen.</li>
          <li>
            Be ansvarig kontakta sin redovisningsekonom för att säkerställa att
            allt är bokfört.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[60] = {
  SE: {
    name: 'Svagt eget kapital',
  },
};

triggerTexts[61] = {
  SE: {
    name: 'Skatt på ej avdragsgill kostnad har ej än belastat resultat',
    info: (
      <p>
        Skatt på ej avdragsgilla kostnader hänvisar vanligtvis till situationer
        där företag eller enskilda personer har kostnader som inte kan dras av
        från deras skattepliktiga intäkter vid beräkningen av skatt.
        <br />
        <br />
        Dessa kostnader betraktas vanligtvis inte som nödvändiga för att
        generera intäkter eller bedriva verksamheten och får därför inte dras av
        som affärskostnader vid beräkningen av skatt.
        <br />
        <br />
        Exempel på sådana ej avdragsgilla kostnader kan vara personliga utgifter
        som inte är relaterade till verksamheten, vissa typer av underhåll eller
        reparationer för personlig användning, eller kostnader som inte är
        förenliga med skattelagstiftningen.
        <br />
        <br />
        Detta är en skatt som vanligtvis bokförs i slutet på året vid bokslut.
        Detta gör att skatten kan påverka resultatet ordentligt ifall de ej
        avdragsgilla kostnaderna är stora.
      </p>
    ),
    requirement: (
      <p>
        Skall tillräknas bolagets inkomstdeklaration för att rätt skatt skall
        betalas.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          Då skatten som Xpektor räknar ut är ett resultat av kostnaden
          multiplicerat med bolagsskatten 20,6% kan du räkna ut hur stora ej
          avdrags gilla kostnaderna är.
          <br />
          <br />
          Exempel: om Xpektor visar 20600 kronor i skatt uppgår de ej
          avdragsgilla kostnader till ca 100 000, detta är att betrakta som
          stora eller små summor i förhållande till bolagets storlek.
          <br />
          <br />
          Ställ följande frågor för att förstå om kostnaderna är legitima:
        </p>
        <ol>
          <li>Transaktioner som har med middagar och resor att göra.</li>
          <li>Räntor på försenade inbetalningar till skatteverket.</li>
          <li>
            Är det kostnadsförda lån/fordringar som de ej kommer få betalt för.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[62] = {
  SE: {
    name: 'Kunder har en genomsnittlig betaltid på över 30 dagar',
    info: (
      <p>
        Betaltid är den tid då kunden har på sig att betala in en ställd
        faktura, en normal betaltid ligger mellan 10-30 dagar efter att fakturan
        har ställts.
        <br />
        <br />
        Är genomsnittet på inbetalningar högre än utsatt betaltid bör man ställa
        frågor.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag redovisar en högre genomsnittlig betaltid finns det
          flera viktiga aspekter att ta hänsyn till för att säkerställa en
          korrekt och säker hantering av denna information. Här är några
          centrala punkter att överväga:
        </p>
        <ol>
          <li>Vad beror sena betalningar på?</li>
          <li>Hur lång kredittid har kunden?</li>
          <li>
            Kan kundens fakturor komma att innebära kundförluster på längre
            sikt?
          </li>
          <li>
            Skulle bolaget kunna använda sig av factoring lösning för kortare
            kredittider?
          </li>
          <li>Skulle kundens fakturor komma att innebära regresser?</li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[63] = {
  SE: {
    name: 'Stora summor på skattekonto',
    info: (
      <p>
        Att ha ett överskott på skattekontot är generellt sett inte en dålig
        sak, eftersom det innebär att bolaget har extra pengar tillgängliga för
        att täcka framtida skatteskulder eller för att begära återbetalning från
        skattemyndigheten.
        <br />
        <br />
        Är överskottet stort i förhållande till bolagets verksamhet bör man
        ställa frågor om överskottet.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <ol>
          <li>
            <b>Identifiera orsaken till överskottet:</b> Är det återbetalningar
            från skatteverket eller egna insättningar.
          </li>
          <li>
            Stora överskott på skattekontot är ej vanligt förekommande då man
            vanligtvis vill kunna använda pengarna i bolagets verksamhet.
          </li>
          <li>Se till att vara säker på överskottets ursprung.</li>
          <li>
            Ta reda på om skatteverket betalt ut tidigare överskott till bolaget
            eller till ett annat konto.
          </li>
          <li>
            <b>Kontakta skattemyndigheten:</b> Det bästa steget är att
            omedelbart kontakta skattemyndighet och få förklarat situationen.
          </li>
          <li>
            <b>Be om historiska transaktioner:</b> Skatteverket är offentligt
            vilket gör att du kan begära skatteinformation
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[64] = {
  SE: {
    name: 'Latenta skatter så som anstånd',
    info: (
      <p>
        Skatteanstånd är en åtgärd som tillåter en skattebetalare att
        senarelägga betalningen av sina skatter till en senare tidpunkt än den
        ursprungliga förfallodagen. Detta kan vara till nytta för personer eller
        företag som temporärt har begränsade likvida medel eller som upplever
        tillfälliga ekonomiska svårigheter.
        <br />
        <br />
        Anstånd för ett företag nämns ofta i sammanhang med coronapandemin där
        företag fick ansöka om anstånd på grund av den ekonomiska situationen
        <br />
        <br />
        Tillfälligt anstånd med betalning innebär att betalningen kan skjutas
        upp i maximalt ett år från beslutsdatumet. Anstånd kunde tidigare sökas
        för maximalt tolv perioder för månadsredovisning, fyra för de som
        redovisar per kvartal och två perioder för de som redovisar moms på
        årsbasis. I samband med att anståndet upphör finns det möjlighet att
        ansöka om förlängning av anståndet först i ytterligare ett år och
        därefter är det möjligt att ansöka om förlängt anstånd i kombination med
        en avbetalningsplan i maximalt ytterligare tre år.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <ol>
          <li>När skall anståndet betalas?</li>
          <li>Finns det täckning för att betala anståndet?</li>
          <li>
            Kan betalningen av anståndet påverka bolagets ställning så pass
            mycket att bolaget kan behöva extra lån eller krediter?
          </li>
          <li>Vilka kortfristiga/långfristiga risker medför anståndet?</li>
          <li>
            Går det att re-finansiera anståndet med ytterligare lån på längre
            tid?
          </li>
          <li>
            Var noga med riskbedömning om bolagets ställning i övrigt inte
            klarar återbetalning av anståndet.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[65] = {
  SE: {
    name: 'Negativt kassaflöde',
    info: (
      <p>
        Ett framtida negativt kassaflöde kan vara en oroande indikation,
        särskilt om det inte är resultatet av en medveten strategisk
        investering. Det kan tyda på potentiella finansiella problem eller
        bristande långsiktig hållbarhet för verksamheten.
        <br />
        <br />
        Orsaker till framtida negativa kassaflöden kan inkludera:
        <br />
        <br />
        1. Otillräcklig likviditet: Om företaget inte har tillräckligt med
        tillgängliga medel för att täcka sina framtida utgifter kan det leda
        till negativt kassaflöde.
        <br />
        <br />
        2. Höga skulder: Om företaget har höga skulder och måste betala räntor
        eller amorteringar som överstiger sina kassaflöden kan det leda till
        negativt kassaflöde.
        <br />
        <br />
        3. Ökade kostnader: Om företaget står inför ökade kostnader, till
        exempel på grund av högre råvarupriser, löner eller driftskostnade.
        <br />
        <br />
        4. Försäljningsminskning: Om företagets försäljning minskar eller om det
        förlorar kunder till konkurrenter kan det resultera i lägre kassaflöden.
        <br />
        <br />
        5. Misslyckade investeringar: Om företaget gör dåliga investeringar som
        inte genererar tillräcklig avkastning kan det leda till framtida
        negativa kassaflöden.
      </p>
    ),
    requirement: (
      <p>
        Bolaget skall upprätta kontrollbalansräkning om man misstänker att
        likviditeten kan orsaka konkurs.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>Fråga ansvarig om följande processer påbörjats av företaget:</p>
        <ol>
          <li>
            <b>Kostnadsminskningar:</b> Identifiera områden där kostnader kan
            minskas utan att äventyra verksamhetens effektivitet eller kvalitet.
            Det kan innebära att minska onödiga utgifter, förhandla om
            leverantörsavtal eller optimera verksamhetsprocesser.
          </li>
          <li>
            <b>Öka intäkterna:</b> Utveckla strategier för att öka försäljningen
            eller diversifiera produktutbudet för att locka nya kunder och
            behålla befintliga. Det kan också innebära att förbättra
            marknadsföringsinsatser eller erbjuda nya produkter eller tjänster.
          </li>
          <li>
            <b>Förbättra kreditvillkor:</b> Förhandla om mer flexibla
            betalningsvillkor med leverantörer eller kunder för att förbättra
            likviditeten och förhindra negativa kassaflöden.
          </li>
          <li>
            <b>Försäljning av tillgångar:</b> Överväg att sälja
            icke-kärntillgångar eller överskottsförråd för att generera
            omedelbara likvida medel som kan användas för att täcka kortsiktiga
            utgifter.
          </li>
          <li>
            <b>Kapitalanskaffning:</b> Utforska möjligheter till extern
            finansiering genom lån, aktieemissioner eller
            riskkapitalinvesteringar för att förbättra likviditeten och
            möjliggöra fortsatt tillväxt.
          </li>
        </ol>
        <p>
          Sammanfattningsvis kan ett negativt kassaflöde ha allvarliga
          ekonomiska och operationella konsekvenser för ett företag och kräver
          snabb och effektiv åtgärd för att undvika långsiktiga skador.
        </p>
      </div>
    ),
  },
};

triggerTexts[66] = {
  SE: {
    name: 'Ej momsregistrerad',
  },
};

triggerTexts[67] = {
  SE: {
    name: 'Ingen revisor',
  },
};

triggerTexts[68] = {
  SE: {
    name: 'Ingen revisor utan revisorsförbehåll',
  },
};

triggerTexts[69] = {
  SE: {
    name: 'Uppvisar intänkter men inga kostnader',
  },
};

triggerTexts[70] = {
  SE: {
    name: 'Eget kapital har ökat under månaden',
  },
};

triggerTexts[71] = {
  SE: {
    name: 'Eget kapital har minskat under månaden',
  },
};

triggerTexts[72] = {
  SE: {
    name: 'De materiella och immateriella anläggningstillgångarna ökar ständigt under längre period',
  },
};

triggerTexts[73] = {
  SE: {
    name: 'Ingen försäljning i tidigare månader',
    info: (
      <p>
        Xpektors algoritmer ger dig en varning när företaget inte haft någon
        försäljning tidigare månader. Xpektor mäter försäljning mellan månader
        för att uppmärksamma bedrägeri, bluffakturering och penningtvätt.
        <br />
        <br />
        Har bolaget endast omsättning i sista månaden bör man vara försiktig och
        sätta sig in i situationen.
      </p>
    ),
    tips: (
      <div className="trigger-tips-container">
        <p>
          När ett bolag enbart redovisar omsättning i innevarande månad finns
          det flera viktiga aspekter att ta hänsyn till för att säkerställa en
          korrekt och säker hantering av denna information. Här är några
          centrala punkter att överväga:
        </p>
        <ol>
          <li>Be företaget skicka ett utdrag från kundreskontran.</li>
          <li>
            Om bolaget har längre livslängd än ett år bör man ifrågasätta
            variationen kraftigt.
          </li>
          <li>
            Gå till rapporter i Xpektor och se på historiken på
            resultaträkningen.
          </li>
        </ol>
      </div>
    ),
  },
};

triggerTexts[74] = {
  SE: {
    name: 'Ingen moms bokas med försäljning',
  },
};

triggerTexts[75] = {
  SE: {
    name: 'Inkasso mer än 10% av transaktionerna',
  },
};

triggerTexts[76] = {
  SE: {
    name: 'Inkasso mer än 30% av transaktionerna',
  },
};

triggerTexts[77] = {
  SE: {
    name: 'Inkasso mer än 75% av transaktionerna',
  },
};

triggerTexts[78] = {
  SE: {
    name: 'Kronofogden',
  },
};

triggerTexts[79] = {
  SE: {
    name: 'Utbetalningar mer än 55% av inbet/utbet',
  },
};

triggerTexts[80] = {
  SE: {
    name: 'Swish mer än 50%',
  },
};

triggerTexts[81] = {
  SE: {
    name: 'Finns spel',
  },
};

triggerTexts[82] = {
  SE: {
    name: 'Ingen betalning till Skatteverket',
  },
};

triggerTexts[83] = {
  SE: {
    name: 'Finns lön men ingen betalning till Skatteverket',
  },
};

triggerTexts[84] = {
  SE: {
    name: 'Inbetalningar större än kundfakturor',
  },
};

triggerTexts[85] = {
  SE: {
    name: 'Genomsnittlig fakturering korrelerar ej med insättningar',
  },
};

triggerTexts[86] = {
  SE: {
    name: 'Utbetalningar större än leverantörsfakturor',
  },
};

triggerTexts[87] = {
  SE: {
    name: 'Kontantuttag bankomat',
  },
};

triggerTexts[88] = {
  SE: {
    name: 'Kontantinsättningar',
  },
};

export function GetTriggerTexts(id) {
  return {
    id,
    texts: triggerTexts[id].SE,
  };
}

export function TranslateTriggers(triggerIds) {
  const translated = [];
  for (let i = 0; i < triggerIds.length; i += 1) {
    translated.push(GetTriggerTexts(triggerIds[i]));
  }
  return translated;
}
