import api from './ApiMiddleware';

const getBanks = (countryCode) => {
  return api.get(`/api/v1/banksession/banks/${countryCode}`);
};

const getAuthLinkForEnableBanking = (
  clientId,
  bankName,
  bankCountry,
  psuType,
  target,
  externalStateId
) => {
  return api.post(`/api/v1/banksession/startauthorization`, {
    clientId,
    bankName,
    bankCountry,
    psuType,
    target,
    externalStateId,
  });
};

const authorizeSession = (clientId, code, externalStateId) => {
  return api.post(`/api/v1/banksession/authorizesession`, {
    clientId,
    code,
    externalStateId,
  });
};
const updateSession = (clientId) => {
  return api.put(`/api/v1/banksession/${clientId}/session`);
};

const getClientBankSession = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/session`);
};

const getBankAccounts = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/bank/accounts`);
};

const getBalances = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/balance`);
};

const getTransactions = (clientId, fromDate, toDate) => {
  return api.get(
    `/api/v1/client/${clientId}/bank/accounts/transactions/b?fromDate=${fromDate}&toDate=${toDate}`
  );
};

const BankService = {
  getBanks,
  getAuthLinkForEnableBanking,
  authorizeSession,
  updateSession,
  getClientBankSession,
  getBankAccounts,
  getBalances,
  getTransactions,
};

export default BankService;
