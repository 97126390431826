/* eslint-disable no-alert */
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthService from '../../Services/AuthService';
import LoadingBar from '../LoadingBar/LoadingBar';

export default function AuthorizeVisma() {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const decodeToken = async () => {
      const error = new URLSearchParams(search).get('error');
      const authCode = new URLSearchParams(search).get('code');
      const state = new URLSearchParams(search).get('state');
      // eslint-disable-next-line react-hooks/exhaustive-deps

      let url = await AuthService.decode(state);
      const ext = new URLSearchParams(url).get('ext');
      if (error === null) {
        if (authCode) {
          url += `&action=createClient&partnerId=1&authCode=${authCode}`;
        }
      } else {
        const errorDescription = new URLSearchParams(search).get(
          'error_description'
        );
        url += `&error_type=accounting&error_description=${errorDescription}&partnerId=1`;

        if (ext !== null) {
          url += `&action=auth`;
        } else {
          url += `&action=createClient`;
        }
      }

      history.push(url);
    };
    decodeToken();
  }, []);
  return <LoadingBar active status="Vänligen vänta..." />;
}
