import api from './ApiMiddleware';

const isXpektorOmbudForClient = (clientId) => {
  return api.get(`/api/v1/skv/${clientId}/ombud/xpektor`);
};

const getDeepLinkSKV = (clientId) => {
  return api.get(`/api/v1/skv/${clientId}/authurl`);
};

const SKVService = {
  isXpektorOmbudForClient,
  getDeepLinkSKV,
};

export default SKVService;
