import api from './ApiMiddleware';

const getEquityStatuses = () => {
  return api.get(`/api/v1/user/companystatuses/latest`);
};

const getLiquidityStatuses = () => {
  return api.get(`/api/v1/user/liquiditystatuses/latest`);
};

const getManagementStatuses = () => {
  return api.get(`/api/v1/user/managementstatuses/latest`);
};

const getScores = () => {
  return api.get(`/api/v1/user/scores/latest`);
};

const getEquityLatest = (id) => {
  return api.get(`/api/v1/client/${id}/companystatus/latest`);
};

const getLiquidityLatest = (id) => {
  return api.get(`/api/v1/client/${id}/liquiditystatus/latest`);
};

const getManagementLatest = (id) => {
  return api.get(`/api/v1/client/${id}/managementstatus/latest`);
};

const getRegulatoryLatest = (id) => {
  return api.get(`/api/v1/regulatory/status/${id}/latest`);
};

const getScoreLatest = (id) => {
  return api.get(`/api/v1/client/${id}/score/latest`);
};

const StatusService = {
  getEquityStatuses,
  getLiquidityStatuses,
  getManagementStatuses,
  getScores,
  getEquityLatest,
  getLiquidityLatest,
  getManagementLatest,
  getRegulatoryLatest,
  getScoreLatest,
};

export default StatusService;
