/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CardButton from '../../../Components/CardButton/CardButton';
import SKVLogo from '../../../Images/Logos/SKV_RGB_li.png';

export default function SkvAuthStep({ allowed, completed, openSkvTab }) {
  return completed ? (
    <div className="auth-step-completed-content">
      <FontAwesomeIcon className="auth-complete-icon" icon={faCircleCheck} />
      <p className="auth-complete-header">Skatteverket är kopplat!</p>
    </div>
  ) : (
    <div className="auth-step">
      <p className="auth-step-header">Koppla på skattekonto</p>
      <p className="auth-step-description">
        Genom att ge tillgång till skattekontot får handläggaren förståelse för
        företagets skatter och regelefterlevnad. Detta är något som underlättar
        handläggningen.
      </p>
      <div className="integration-options-column">
        <CardButton
          textHeader="Skatteverket"
          textContent="Kräver en koppling till bokföringssystem eller bankkonto"
          icon={faArrowRight}
          image={SKVLogo}
          onClick={openSkvTab}
          disabled={!allowed}
          styleClass={!allowed ? 'integration-partner-button-disabled' : ''}
        />
      </div>
    </div>
  );
}
