/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Searchbar from '../../../Components/Searchbar/Searchbar';

export default function BankAuthStep({
  completed,
  banks,
  redirectToBank,
  error,
  errorText,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredBanks = searchQuery
    ? banks.filter((bank) => {
        return bank.name?.toLowerCase().includes(searchQuery?.toLowerCase());
      })
    : banks;

  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon
            className="auth-complete-icon"
            icon={faCircleCheck}
          />
          <p className="auth-complete-header">Banken är kopplad!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-header">Koppla på bankkonto</p>
          <p className="auth-step-description">
            Genom att koppla ditt företagskonto till Xpektor slipper du manuellt
            samla in och skicka in bankutdrag. Xpektor hämtar automatiskt den
            information som behövs vilket underlättar och snabbar på hanteringen
            av din förfrågan.
          </p>
          {error ? (
            <div className="auth-step-error">
              <p>
                {errorText ??
                  'Något gick fel och det gick inte att koppla banken.'}
              </p>
            </div>
          ) : (
            ''
          )}
          <Searchbar
            placeholder="Sök bank"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="bank-grid">
            {filteredBanks.map((data) => {
              return (
                <div
                  className="bank-button"
                  onClick={() =>
                    redirectToBank(data.name, data.country, data.psuType)
                  }
                  role="button"
                  tabIndex="0"
                  key={`${data.name} ${data.country} ${data.bic} ${data.psuType}`}
                >
                  <div className="button-content-container">
                    <p>{`${data.name} (${
                      data.psuType === 0 ? 'Business' : 'Personal'
                    })`}</p>
                    <img
                      className="bank-image"
                      src={data.logo}
                      alt="Bank logo"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
