/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'react';
import './Score.scss';
import Module from '../Module';
import StatusService from '../../../../../Services/StatusService';
import DataPanel from '../../../../../Components/DataPanel/DataPanel';
import LoadingBar from '../../../../../Components/LoadingBar/LoadingBar';
import { TranslateTriggers } from '../../../../../Components/Utilities/TriggerTexts';

export default function Score({ client }) {
  const [score, setScore] = useState({});
  const [overallGrade, setOverallGrade] = useState('');
  const [economicTriggers, setEconomicTriggers] = useState([]);
  const [regulatoryTriggers, setRegulatoryTriggers] = useState([]);
  const [managementTriggers, setManagementTriggers] = useState([]);
  const [accountTriggers, setAccountTriggers] = useState([]);
  // const [unbookedTriggers, setUnbookedTriggers] = useState([]);

  const economicRef = useRef();
  const regulatoryRef = useRef();
  const managementRef = useRef();
  const accountRef = useRef();

  const gradeBackgroundColors = {
    A: '#c3e9c4',
    B: 'rgb(225, 255, 191)',
    C: 'rgb(255, 253, 231)',
    D: 'rgb(255, 234, 195)',
    E: 'rgb(255, 208, 208)',
    '-': 'rgb(233, 233, 233)',
  };

  const gradeDescriptions = {
    A: 'Företaget visar på en mycket god prestation, företaget visar ingen risk i att inom snar framtid hamna på obestånd eller på annat sätt utsätta sina borgenärer för risk.',
    B: 'Företaget visar ett gott helhetsintryck. Företaget kan anses vara stabilt och verkar inte ha några hinder att uppfylla eventuella förpliktelser mot sina borgenärer.',
    C: 'Företaget verkar ha vissa svårigheter och/eller kan komma att få svårigheter att uppfylla sina förpliktelser gentemot sina borgenärer.',
    D: 'Företaget uppvisar svårigheter och hinder som gör att företaget förmodligen inte kan ses som stabilt, och därför inte kommer kunna uppfylla sina framtida skyldigheter mot eventuella borgenärer.',
    E: 'Företaget uppvisar väsentliga hinder och svårigheter, det finns en verklig och överhängande risk att eventuella borgenärer inte kommer kunna återvinna eventuella krediter.',
    '-': 'Data saknas för att kunna göra en bedömning',
  };

  const checkForNull = (grade) => {
    if (grade == null) {
      return '-';
    }
    return grade;
  };

  useEffect(() => {
    const getScore = async () => {
      await StatusService.getScoreLatest(client.id)
        .then((response) => {
          setEconomicTriggers(
            TranslateTriggers(response.data.economicTriggers)
          );
          setRegulatoryTriggers(
            TranslateTriggers(response.data.regulatoryTriggers)
          );
          setManagementTriggers(
            TranslateTriggers(response.data.managementTriggers)
          );
          setAccountTriggers(TranslateTriggers(response.data.accountTriggers));
          // setUnbookedTriggers(response.data.unbookedTriggers);

          setOverallGrade(checkForNull(response.data.overallScore));
          setScore(response.data);
        })
        .catch(() => {
          setScore(null);
        });
    };
    if (Object.keys(client).length !== 0) {
      getScore();
    }
  }, [client]);

  function MapTriggers(triggers) {
    return triggers.map((trigger) => {
      return (
        <DataPanel.TriggerEntry
          name={trigger.texts.name}
          info={trigger.texts.info}
          requirement={trigger.texts.requirement}
          key={trigger.id}
          tooltipId={trigger.id}
          expandedContent={trigger.texts.tips}
        />
      );
    });
  }

  function AnyDataMissing() {
    if (
      score.economicScore === null ||
      score.managementScore === null ||
      score.regulatoryScore === null ||
      score.accountScore === null
    )
      return true;

    return false;
  }

  const renderOverallInfoCategory = (title, grade, onClick) => {
    return (
      <button className="summary-grade-button" onClick={onClick} type="button">
        <p
          className={`summary-grade grade-${grade}`}
          style={{
            backgroundColor: gradeBackgroundColors[grade],
          }}
        >
          {grade}
        </p>
        <p className="summary-grade-title">{title}</p>
      </button>
    );
  };

  return (
    <Module>
      {score == null ? (
        <p className="module-empty-text">
          Kunde inte hämta score. Om klienten precis har skapats kan det ta
          några minuter att analysera datan.
        </p>
      ) : Object.keys(score).length === 0 ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : (
        <div className="score-wrapper">
          <div className="score-header">
            <div
              className={`score-gauge ${
                !client.externalConnectionActive && !client.bankConnectionActive
                  ? 'score-gauge-disconnected grade--'
                  : `grade-${overallGrade}`
              }`}
            >
              <p
                className={`score-gauge-grade ${
                  !client.externalConnectionActive &&
                  !client.bankConnectionActive
                    ? 'grade--'
                    : `grade-${overallGrade}`
                }`}
              >
                {overallGrade === '-' ? '?' : overallGrade}
              </p>
            </div>
            <div className="score-overall-info">
              <p className="overall-info-title">Övergripande</p>
              <p className="overall-info-text">
                {gradeDescriptions[overallGrade]}
              </p>
              {AnyDataMissing() && (
                <p className="overall-info-disclaimer">
                  <b>
                    Obs! Viss data saknas för att göra en komplett bedömning.
                    Ratingen är baserad på den data som finns.
                  </b>
                </p>
              )}
              <p className="beta-sticker">BETA</p>
            </div>
            <div className="score-grade-summary">
              <p className="grade-summary-title">Analys per kategori</p>
              {renderOverallInfoCategory(
                'Ekonomiskt',
                checkForNull(score.economicScore),
                () => economicRef.current.scrollIntoView({ behavior: 'smooth' })
              )}
              {renderOverallInfoCategory(
                'Förvaltning',
                checkForNull(score.managementScore),
                () =>
                  managementRef.current.scrollIntoView({ behavior: 'smooth' })
              )}
              {renderOverallInfoCategory(
                'Regulatoriskt',
                checkForNull(score.regulatoryScore),
                () =>
                  regulatoryRef.current.scrollIntoView({ behavior: 'smooth' })
              )}
              {renderOverallInfoCategory(
                'Kontoanalys',
                checkForNull(score.accountScore),
                () => accountRef.current.scrollIntoView({ behavior: 'smooth' })
              )}
            </div>
          </div>
          <div className="score-content">
            <div className="score-content-column">
              <DataPanel
                title="Ekonomiskt"
                iconText={checkForNull(score.economicScore)}
                givenRef={economicRef}
              >
                {economicTriggers.length > 0 ? (
                  MapTriggers(economicTriggers)
                ) : score.economicScore === null ? (
                  <DataPanel.TriggerEntry name="Data saknas för att kunna göra en bedömning." />
                ) : (
                  <DataPanel.TriggerEntry name="Inga anmärkningar" />
                )}
              </DataPanel>
              <DataPanel
                title="Förvaltning"
                iconText={checkForNull(score.managementScore)}
                givenRef={managementRef}
              >
                {managementTriggers.length > 0 ? (
                  MapTriggers(managementTriggers)
                ) : score.managementScore === null ? (
                  <DataPanel.TriggerEntry name="Data saknas för att kunna göra en bedömning." />
                ) : (
                  <DataPanel.TriggerEntry name="Inga anmärkningar" />
                )}
              </DataPanel>
              <DataPanel
                title="Regulatoriskt"
                iconText={checkForNull(score.regulatoryScore)}
                givenRef={regulatoryRef}
              >
                {regulatoryTriggers.length > 0 ? (
                  MapTriggers(regulatoryTriggers)
                ) : score.regulatoryScore === null ? (
                  <DataPanel.TriggerEntry name="Data saknas för att kunna göra en bedömning." />
                ) : (
                  <DataPanel.TriggerEntry name="Inga anmärkningar" />
                )}
              </DataPanel>
              <DataPanel
                title="Kontoanalys"
                iconText={checkForNull(score.accountScore)}
                givenRef={accountRef}
              >
                {accountTriggers.length > 0 ? (
                  MapTriggers(accountTriggers)
                ) : score.accountScore === null ? (
                  <DataPanel.TriggerEntry name="Data saknas för att kunna göra en bedömning." />
                ) : (
                  <DataPanel.TriggerEntry name="Inga anmärkningar" />
                )}
              </DataPanel>
              {/* <DataPanel title="Ej bokförda poster">
                {unbookedTriggers.length > 0 ? (
                  MapTriggers(unbookedTriggers)
                ) : (
                  <DataPanel.ScoreTriggerEntry name="Inga anmärkningar" />
                )}
              </DataPanel> */}
            </div>
            <div className="score-content-column" />
          </div>
        </div>
      )}
    </Module>
  );
}
